<template>
  <div class="container">
    <div class="handle-box">
      <el-select v-model="query.grade" placeholder="Grade" class="mr10" :clearable="true">
        <el-option
          v-for="item in options.gradeList"
          :key="item.value"
          :label="item.description"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-input
        v-model="query.studentId"
        placeholder="Student No."
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-input
        v-model="query.username"
        placeholder="Student Name"
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-input
        v-if="false"
        v-model="query.email"
        placeholder="Email"
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-button type="primary" @click="handleSearch">
        <el-icon>
          <search />
        </el-icon>
        Search
      </el-button>
      <div style="float: right">
        <el-button type="primary" @click="clearSelection"> Clear selection </el-button>
        <el-button type="primary" @click="handleActionForSelectedStudents">
          <el-icon>
            <plus />
          </el-icon>
          {{ handleSelectedButtonText }}
        </el-button>
        <el-button type="success" @click="handleActionByCurrentCondition">
          <el-icon>
            <plus />
          </el-icon>
          Import by Condition
        </el-button>
      </div>
    </div>
    <div class="selectedStudentsTag">
      <el-tag v-for="student in studentList" :key="student.studentId" type="info">
        {{ student.studentId }}
      </el-tag>
    </div>
    <el-table
      :data="tableData"
      border
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
      row-key="studentId"
      @sort-change="changeSort"
      :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      @selection-change="handleStudentSelectionChange"
    >
      <el-table-column
        type="selection"
        :width="this.$widthRatio * 55 + 'px'"
        :reserve-selection="true"
      />
      <el-table-column
        prop="studentId"
        label="Student No."
        :width="this.$widthRatio * 100 + 'px'"
      ></el-table-column>
      <el-table-column
        prop="username"
        label="Student Name"
        :width="this.$widthRatio * 160 + 'px'"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column
        prop="createdTime"
        sortable="true"
        :formatter="$tableCellFormatter.formatDate"
        label="Enroll Time"
        :width="this.$widthRatio * 120 + 'px'"
      ></el-table-column>
      <el-table-column
        label="Operation"
        :width="this.$widthRatio * 100 + 'px'"
        align="center"
      >
        <template #default="scope">
          <el-button
            type="text"
            @click="handleActionForCurrentStudent(scope.$index, scope.row)"
          >
            <el-icon> <circle-plus /> </el-icon>
            {{ handleRowButtonText }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="itemTotal"
        :page-count="pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "studentsSelection",
  props: {
    initData: {
      parent: Object,
      confirmFunction: Function,
    },
  },
  data() {
    return {
      controllerUrl: "/student",
      query: {
        studentId: null,
        username: null,
        mobileNo: null,
        email: null,
        startEnrolledTime: null,
        endEnrolledTime: null,
        grade: null,
        status: "Normal",
        orderBy: null,
        orderDirection: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options: {
        gradeList: [],
      },
      handleSelectedButtonText: "Import",
      handleRowButtonText: "Confirm",
      studentList: [],
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleActionForSelectedStudents() {
      let studentList = this.studentList;
      this.handleStudents(studentList);
    },
    handleActionByCurrentCondition() {
      let q = JSON.parse(JSON.stringify(this.query));
      q.pageIndex = 1;
      q.pageSize = 0x7fffffff;
      getData(this.controllerUrl, q, {
        isShowLoading: false,
      }).then((res) => {
        let resData = res.result;
        let studentList = resData.list;
        console.log("handleActionByCurrentCondition", studentList);
        this.handleStudents(studentList);
      });
    },
    handleActionForCurrentStudent(index, row) {
      let studentList = [row];
      this.handleStudents(studentList);
    },
    handleStudents(studentList) {
      this.initData.confirmFunction.apply(this.initData.parent, [studentList]);
    },
    clearSelection() {
      this.studentList = [];
      this.$refs.multipleTable.clearSelection();
    },
    handleStudentSelectionChange(rows) {
      if (this.tableData && this.tableData.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          let index = this.studentList.indexOf(this.tableData[i]);
          if (index >= 0) {
            this.studentList.splice(index, 1);
          }
        }
      }
      if (rows && rows.length) {
        for (let i = 0; i < rows.length; i++) {
          if (this.studentList.indexOf(rows[i]) >= 0) continue;
          this.studentList.push(rows[i]);
        }
      }
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.selectedStudentsTag {
  margin: 20px 0;
}
.selectedStudentsTag .el-tag {
  margin: 4px;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
